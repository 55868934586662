.content-layout{
    display: grid;
    min-height: 100vh;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 120px 1fr 50px;
    grid-template-areas:    "header     header      header"
                            "contenido  contenido   contenido"
                            "footer     footer      footer"
                        ;

    header{
        grid-area: header;
        display: flex;
        flex-direction: row;
    }

    main{
        background: #ffffff;
        grid-area: contenido;
    }

    footer {
        background: rgb(46, 46, 46);
        grid-area: footer;
    }
}

@media screen and (max-width:780px){
    .content-layout{
        grid-template-columns: 1fr;
        grid-template-rows: 120px 1fr 50px;
        grid-template-areas: "header"
                            "contenido"
                            "footer"                         
        ;
    }
}